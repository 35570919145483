import { FileExtentions, SortDirections } from "@/utils/GeneralConstants";
import jp from "jsonpath";
import { StoreException } from "@/utils/ErrorHandler";
import IdUtils from "@/utils/IdUtils";

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Gets a filename, removes unsafe characters and sets file extension to lower .
 *
 * Unsafe characters in this case are: %
 *
 * @param string
 * @param timestamp
 * @returns {string|*}
 */
export function removeUnsafeCharacters(string, timestamp) {
  if (string && typeof string === "string") {
    //Check if file extension is present
    const indexOfSuffix = string.lastIndexOf(".");
    if (indexOfSuffix >= 0) {
      // Get the file name and remove the unsafe characters:
      const fileName = string
        .slice(0, indexOfSuffix)
        .replaceAll(/[%,<>\\*\\?\\|\\/'":`]/g, "");
      // Get the file extension and set it to lower case:
      const suffix = string.slice(indexOfSuffix + 1).toLowerCase();
      // If there is a timestamp, replace the ":" character:
      const safeTimestamp = timestamp ? timestamp.replace(/:/g, "_") : null;
      // Set the modified file name:
      const modifiedFileName = safeTimestamp
        ? `${fileName}-${safeTimestamp}`
        : fileName;
      // return the modified file name with the file extension:
      return `${modifiedFileName}.${suffix}`;
    }
  }
  return string;
}

/**
 * The function allows for sorting by one property or by two.
 * If provided, the secondSortingProperty will be analyzed after the first one
 * and order will be affected accordingly
 * @param sortingProperty
 * @param secondSortingProperty
 * @param direction
 * @returns {function(...[*]=)}
 */
export function createCustomSortingFunction(
  sortingProperty,
  secondSortingProperty = undefined,
  direction = null
) {
  if (
    sortingProperty &&
    "string" === typeof sortingProperty &&
    sortingProperty.length > 0
  ) {
    // Assign values
    let sortingProp = sortingProperty;
    let secondSortingProp = secondSortingProperty;

    // Create ascending sorting function
    if (direction === SortDirections.ASCENDING) {
      return function (a, b) {
        if (sortingProperty.includes("content")) {
          sortingProp = sortingProperty.slice(8);
          a = a.content;
          b = b.content;
        }
        if (a[sortingProp] > b[sortingProp] || !b[sortingProp]) {
          return 1;
        }
        if (a[sortingProp] < b[sortingProp] || !a[sortingProp]) {
          return -1;
        }
        // If there's a second sorting property – include it
        if (secondSortingProp && a[secondSortingProp] > b[secondSortingProp]) {
          return 1;
        }
        if (secondSortingProp && a[secondSortingProp] < b[secondSortingProp]) {
          return -1;
        }
        return 0;
      };
      // Create descending sorting function
    } else if (direction === SortDirections.DESCENDING) {
      return function (a, b) {
        if (sortingProperty.includes("content")) {
          sortingProp = sortingProperty.slice(8);
          a = a.content;
          b = b.content;
        }

        if (a[sortingProp] > b[sortingProp] || !a[sortingProp]) {
          return -1;
        }
        if (a[sortingProp] < b[sortingProp] || !b[sortingProp]) {
          return 1;
        }
        // If there's a second sorting property – include it
        if (secondSortingProp && a[secondSortingProp] > b[secondSortingProp]) {
          return -1;
        }
        if (secondSortingProp && a[secondSortingProp] < b[secondSortingProp]) {
          return 1;
        }
        return 0;
      };
    }
  } else {
    throw new Error("Cannot generate sorting function without input");
  }
}

export function sortByScoreAndLabel(a, b) {
  if (
    a.hasOwnProperty("individualScore") &&
    b.hasOwnProperty("individualScore") &&
    a.hasOwnProperty("label") &&
    b.hasOwnProperty("label")
  ) {
    return createCustomSortingFunction(
      "individualScore",
      "label",
      SortDirections.ASCENDING
    )(a, b);
  } else {
    return null;
  }
}

/**
 * Checks if certain user has a certain role
 * @param user
 * @param roleId
 * @returns {*}
 * @constructor
 */
export const checkIfUserHasThisRole = function (user, roleId) {
  const userRoles = user.relationships.roles;
  return userRoles.some((role) => role.role.identification === roleId);
};

/**
 * Returen a date with format dd-MM-yyyy
 * @param value Date
 * @returns {string}
 */
export function displayDate(value) {
  const date = new Date(value);

  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  day = day < 10 ? `0${day}` : day;
  month = month < 10 ? `0${month}` : month;

  return `${day}-${month}-${year}`;
}

/**
 * Returns a number formatted to a currency. Euro by default
 * @param value
 * @param locale
 * @param currency
 * @returns {string}
 */
export function displayCurrency(value, locale = "nl-NL", currency = "EUR") {
  const number = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);

  // Replace ,00 with ,-
  return number.replace(/\,00$/, ",-");
}

/**
 * Determines the names of possible routes to navigate to based on the current route name.
 * @param routeName
 * @returns {{requestRouteName: undefined, tabName: undefined, sectionRouteName: undefined, actionRouteName: undefined, candidateRouteName: undefined, actionInstanceRouteName: undefined, bulkActionRouteName: undefined}}
 */
const ACTIONS = "actions";
const CANDIDATES = "candidates";

const CandidatesTabRoutes = [
  "Aanvraag",
  "AanvraagTab",
  "LBAVacature",
  "LBAVacatureTab",
  "RecruitmentAanvraag",
  "RecruitmentAanvraagTab",
  "Kandidaat",
  "KandidaatActies",
  "Bulkactie",
  "ActiesAanvraagKandidaat",
  "ActiesAanvraagKandidaatActies",
  "ActiesAanvraagActie",
  "ActiesAanvraagBulkactie",
  "RecruitmentKandidaat",
  "RecruitmentKandidaatActies",
  "LBAVacatureKandidaat",
  "LBAVacatureKandidaatActies",
  "RecruitmentAanvraagBulkactie",
  "LBAVacatureBulkactie",
];

const ActionsTabRoutes = [
  "KandidaatActie",
  "Actie",
  "ActiesAanvraagKandidaatActie",
  "RecruitmentKandidaatActie",
  "RecruitmentAanvraagActie",
  "LBAVacatureKandidaatActie",
  "LBAVacatureActie",
  "LosseVacatureActie",
];

export function getRouteName(routeName) {
  let actionRouteName,
    candidateRouteName,
    bulkActionRouteName,
    actionInstanceRouteName,
    requestRouteName,
    sectionRouteName = null;

  switch (routeName) {
    case "Aanvraag":
    case "AanvraagTab":
      actionRouteName = "Actie";
      candidateRouteName = "Kandidaat";
      bulkActionRouteName = "Bulkactie";
      break;
    case "LBAVacature":
    case "LBAVacatureTab":
      actionRouteName = "LBAVacatureActie";
      candidateRouteName = "LBAVacatureKandidaat";
      bulkActionRouteName = "LBAVacatureBulkactie";
      break;
    case "RecruitmentAanvraag":
    case "RecruitmentAanvraagTab":
      actionRouteName = "RecruitmentAanvraagActie";
      candidateRouteName = "RecruitmentKandidaat";
      bulkActionRouteName = "RecruitmentAanvraagBulkactie";
      break;
    case "ActiesAanvraag":
    case "ActiesAanvraagTab":
      actionRouteName = "ActiesAanvraagActie";
      candidateRouteName = "ActiesAanvraagKandidaat";
      bulkActionRouteName = "ActiesAanvraagBulkactie";
      break;
    case "LosseVacature":
    case "LosseVacatureTab":
      actionRouteName = "LosseVacatureActie";
      break;
    case "Sollicitaties":
    case "SollicitatieDetails":
      candidateRouteName = "SollicitatieDetails";
      actionInstanceRouteName = "Sollicitaties";
      actionRouteName = "SollicitatieDetailsActie";
      break;
    case "SollicitatieDetailsActie":
      actionInstanceRouteName = "SollicitatieDetails";
      break;
    case "KandidaatActie":
      actionInstanceRouteName = "KandidaatActies";
      break;
    case "Kandidaat":
    case "KandidaatActies":
    case "Bulkactie":
    case "Actie":
      actionInstanceRouteName = "AanvraagTab";
      actionRouteName = "KandidaatActie";
      break;
    case "ActiesAanvraagKandidaatActie":
      actionInstanceRouteName = "ActiesAanvraagKandidaatActies";
      break;
    case "ActiesAanvraagKandidaat":
    case "ActiesAanvraagKandidaatActies":
    case "ActiesAanvraagActie":
    case "ActiesAanvraagBulkactie":
      actionInstanceRouteName = "ActiesAanvraagTab";
      actionRouteName = "ActiesAanvraagKandidaatActie";
      break;
    case "ProfielActie":
      actionInstanceRouteName = "Profiel";
      break;
    case "RecruitmentKandidaatActie":
      actionInstanceRouteName = "RecruitmentKandidaatActies";
      break;
    case "RecruitmentKandidaat":
    case "RecruitmentKandidaatActies":
    case "RecruitmentAanvraagActie":
      actionInstanceRouteName = "RecruitmentAanvraagTab";
      actionRouteName = "RecruitmentKandidaatActie";
      break;
    case "LBAVacatureKandidaatActie":
      actionInstanceRouteName = "LBAVacatureKandidaatActies";
      break;
    case "LBAVacatureKandidaat":
    case "LBAVacatureKandidaatActies":
    case "LBAVacatureActie":
      actionInstanceRouteName = "LBAVacatureTab";
      actionRouteName = "LBAVacatureKandidaatActie";
      break;
    case "LBAProfielActie":
      actionInstanceRouteName = "LBAProfiel";
      break;
    case "LosseVacatureActie":
      actionInstanceRouteName = "LosseVacatureTab";
      break;
    case "NieuweAanvraag":
      requestRouteName = "Aanvraag";
      sectionRouteName = "WervingSelectie";
      break;
    case "NieuweLosseVacature":
      requestRouteName = "LosseVacature";
      sectionRouteName = "Recruitment";
      break;
    case "RecruitmentAanvraagBulkactie":
      actionInstanceRouteName = "RecruitmentAanvraag";
      break;
    case "LBAVacatureBulkactie":
      actionInstanceRouteName = "LBAVacature";
      break;
    case "TalentpoolProfielActie":
      actionInstanceRouteName = "TalentpoolProfiel";
      break;
    default:
      // Do nothing
      break;
  }

  const tabName = setTabName(routeName);

  return {
    actionRouteName: actionRouteName,
    candidateRouteName: candidateRouteName,
    bulkActionRouteName: bulkActionRouteName,
    requestRouteName: requestRouteName,
    tabName: tabName,
    actionInstanceRouteName: actionInstanceRouteName,
    sectionRouteName: sectionRouteName,
  };
}

export function setTabName(routeName) {
  if (CandidatesTabRoutes.includes(routeName)) {
    return CANDIDATES;
  } else if (ActionsTabRoutes.includes(routeName)) {
    return ACTIONS;
  }
  return null;
}

export function getGeneralColumnName(value) {
  const endIndex = value.indexOf(".") + 1;
  value = value.slice(endIndex, value.length);
  let name = value;

  switch (value) {
    case "identification":
      name = "Identificatie";
      break;
    case "id":
      name = "ID";
      break;
    case "name":
      name = "Naam";
      break;
    case "timestamp_created":
      name = "Datum aangemaakt";
      break;
    case "timestamp_any_update":
      name = "Datum laatst gewijzigd";
      break;
    case "Welk type capaciteit vraag je aan?":
      name = "Type capaciteit";
      break;
    default:
      name = name[0].toUpperCase() + name.slice(1);
  }
  return name;
}

export function getColumnWidth(label) {
  if (label && label.length) {
    const width = 150 + label.length * 6;
    return width > 270 ? "270px" : width + "px";
  }
  return null;
}

export function valueFromPath(data, path) {
  //Make sure the path is formatted as a JSONPath
  //Add $. of it's not present
  const regex = /^\$/g;
  path = path.match(regex) || path === "" ? path : `$.${path}`;

  const value = jp.query(data, path);

  return value[0]; //jsonpath will return the single value as an array, so return value[0]
}

/**
 * Function to generate full name based on provided first and last name
 * @param firstname
 * @param lastName
 * @returns {string|*}
 */
export function getFullName(firstname, lastName) {
  if (!firstname && !lastName) {
    return null;
  } else if (firstname) {
    return lastName ? `${firstname} ${lastName}` : firstname;
  }
}

/**
 * Function to verify whether a file is of type image
 * @param fileName
 * @returns {boolean}
 */
export function isImage(fileName) {
  if (!fileName?.length) {
    return false;
  } else {
    let fileExtension = fileName.slice(fileName.lastIndexOf(".") + 1);
    fileExtension = fileExtension.toLowerCase();
    const extensions = [
      "apng",
      "avif",
      "gif",
      "jpg",
      "jpeg",
      "jfif",
      "pjpeg",
      "pjp",
      "png",
      "svg",
      "webp",
    ];
    return extensions.includes(fileExtension);
  }
}

/**
 * Function to verify whether a file is of type PDF
 * @param metadata
 * @returns {boolean}
 */
export function isPDF(metadata) {
  if (metadata?.["pdf_version_available"]) {
    return true;
  } else if (metadata?.mime_type) {
    return metadata.mime_type === "application/pdf";
  } else if (metadata?.filename) {
    let fileExtension = metadata.filename.slice(
      metadata.filename.lastIndexOf(".") + 1
    );
    fileExtension = fileExtension.toLowerCase();
    return fileExtension === "pdf";
  } else {
    return false;
  }
}

export function checkMimeType(metadata) {
  // Check metadata (mimetype)
  if (!metadata.mime_type) {
    // Check for common file extensions not supported by upload component
    let fileExt = "";
    if (metadata.name && metadata.name.length) {
      fileExt = metadata.name.slice(metadata.name.lastIndexOf(".") + 1);
      if (
        fileExt &&
        !FileExtentions[fileExt] &&
        metadata.filename &&
        metadata.filename.length
      ) {
        fileExt = metadata.filename.slice(
          metadata.filename.lastIndexOf(".") + 1
        );
      }
    } else if (metadata.filename && metadata.filename.length) {
      fileExt = metadata.filename.slice(metadata.filename.lastIndexOf(".") + 1);
    }
    fileExt = fileExt.toLowerCase();

    if (fileExt && FileExtentions[fileExt]) {
      metadata.mime_type = FileExtentions[fileExt];
    } else {
      throw new StoreException("Unsupported media type");
    }
  }
  return metadata;
}

export function validateStoreInput(
  input,
  requiredProperties = [],
  messageStart = "Could not validate store input: "
) {
  if (!input) {
    throw new StoreException(`${messageStart} nothing received to validate`);
  } else if (!Array.isArray(requiredProperties)) {
    throw new StoreException(
      `${messageStart} invalid properties to validate, please provide a list of properties to validate`
    );
  } else if (requiredProperties.length > 0) {
    const missingProperties = [];
    for (const prop of requiredProperties) {
      if (
        !input.hasOwnProperty(prop) ||
        (prop === "identification" && !IdUtils.isUUID(input[prop]))
      ) {
        missingProperties.push(prop);
      }
    }

    if (missingProperties.length > 0) {
      throw new StoreException(
        `${messageStart} Missing properties:  ${missingProperties.toString()}`
      );
    }
  }
  return true;
}

/**
 * Function to validate media paths based on the following conditions:
 *  - paths are separated on /
 *  - a path should have a minimum length of 1
 * @param string
 */
export function validateMediaNameOrPath(string) {
  // Check if a path is provided
  if (typeof string === "string") {
    // Special case, empty string, also valid path
    if (string === "") {
      return true;
    }

    // Remove any initial or closing /
    if (string.indexOf("/") === 0) {
      string = string.slice(1);
    }
    if (string.lastIndexOf("/") === string.length - 1) {
      string = string.slice(0, string.length - 1);
    }

    // Split the path on separation char /
    const pathParts = string.split("/");
    for (const pathPart of pathParts) {
      // Check each path segment
      if (pathPart.trim().length === 0 || pathPart.includes("%")) {
        return false;
      }
    }
  } else {
    // Input should be a string
    return false;
  }
  return true;
}

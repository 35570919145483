export const Prefixes = Object.freeze({
  ALL: [
    "sollicitaties",
    "aanvragen",
    "acties",
    "actions",
    "profiel",
    "profielen",
    "vacatures",
    "actie",
    "kandidaat",
    "tab",
    "source",
    "dashboard",
    "sollicitaties",
    "recruitment",
    "wervingselectie",
    "loopbaanadvies",
    "database",
    "nieuweaanvraag",
    "profile",
    "bulkactie",
    "lossevacatures",
    "talentpool",
  ],
  NOT_SHOW_IN_BREADCRUMBS: [
    "profile",
    "actie",
    "actions",
    "kandidaat",
    "tab",
    "source",
    "candidates",
    "details",
    "bulkactie",
  ],
  INSTANCE: [
    "sollicitaties",
    "aanvragen",
    "acties",
    "actions",
    "profiel",
    "profielen",
    "vacatures",
    "lossevacatures",
    "talentpool",
  ],
  ACTION: "actie",
  BULK_ACTION: "bulkactie",
  CANDIDATE: "kandidaat",
  TAB: "tab",
  SOURCE: "source",
  TALENTPOOL: "talentpool",
  REQUEST_INSTANCE: [
    "sollicitaties",
    "aanvragen",
    "acties",
    "actions",
    "vacatures",
    "lossevacatures",
  ],
  PROFILE_INSTANCE: "profielen",
  MY_PROFILE_INSTANCE: "profiel",
  MY_SOLLICITATIES: "sollicitaties",
  DETAILS: "details",
});

export const NotShowCrumbs = Object.freeze(["tab", "source", "details"]);

export const DynamicTypes = Object.freeze({
  INSTANCE: "instance",
  ACTION: "action",
  CANDIDATE: "candidate",
  TAB: "tab",
  SOURCE: "source",
  APPLICANT: "details",
  BULK_ACTION: "bulkactie",
  TALENTPOOL_PROFILE: "talentpool_profile",
});

export const RouteNames = Object.freeze({
  AANVRAGEN: "Aanvragen",
  RECRUITMENT: "Recruitment",
});

export const Breadcrumbs = Object.freeze({
  INSTANCE: "instance",
  CANDIDATE: "candidate",
  PROFILE: "profile",
  ACTION: "action",
  TAB: "tab",
  SOURCE: "source",
  TALENTPOOL_PROFILE: "talentpool_profile",
});

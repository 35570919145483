import { BulkActions } from "@/utils/GeneralConstants";

export const AanvraagSpecialActions = Object.freeze({
  WIJZIGEN_BACKOFFICE_MEDEWERKER: "00bd2bb1-a7c6-4d48-b16c-ee3d9fb722e3",
  WIJZIGEN_SELECTIECOMMISSIELEDEN: "2c7b1379-d233-4090-96f0-fc7ea8fc57f5",
  WIJZIGEN_CONTACTPERSONEN: "a8bb656a-3928-41f1-a434-4db6e3e7472e",
  AANPASSEN_EMAILADRESSEN: "df45717e-63b6-492c-8a2b-b31a2d54dda1",
  WIJZIGEN_AANVRAAG: "291c7c7e-b350-4857-a874-76324c7601ae",
  KOPIEREN_AANVRAAG: "1e880b5e-48a4-4751-a20e-dde9d14475ae",
  ANNULEREN_AANVRAAG: "68ca9c58-d480-4b4a-9b18-73337ec5e996",
  ANNULEREN_AANVRAAG_BACKOFFICE: "419902c2-f161-482a-aacf-03e9f49bd91a",
  TOEVOEGEN_EXTERNE_KANDIDAAT_AAN_VACATURE:
    "f167567e-1ef6-43cf-9af1-47550a35de11",
  TOEVOEGEN_INTERNE_KANDIDAAT_AAN_VACATURE:
    "9003c527-b9c9-40b2-8c5a-8006bcf14e9e",
});

export const AanvraagNonShowActions = Object.freeze({
  AANMAKEN_INTERNE_KANDIDAAT: "51612027-a3d0-45e5-9cda-c598c8736fbe",
  AANMAKEN_EXTERNE_KANDIDAAT: "b8f43e03-9669-4845-b489-ed5826513ea6",
  ...BulkActions,
});

export const SpecialIdentifications = Object.freeze({
  WIJZIGEN_CONTACTPERSONEN: "a8bb656a-3928-41f1-a434-4db6e3e7472e",
  KOPIEREN_AANVRAAG: "1e880b5e-48a4-4751-a20e-dde9d14475ae",
  ...BulkActions,
});

export const NotAllowedToContinueActionIds = Object.freeze([
  "bf0ad64a-892d-41e9-86d4-6bac0d18a0e4",
]);

export const EndStatuses = Object.freeze([
  "AFGEWEZEN",
  "AVW OVEREENGEKOMEN",
  "INGETROKKEN",
  "GEEN MATCH",
]);

export const ContextRoles = Object.freeze({
  SELECTIECOMMISSIE: {
    name: "Selectiecommissie",
  },
  MANAGER: {
    name: "Manager",
  },
});

export const InformationTabs = Object.freeze({
  AANVRAAG_GEGEVENS: "Aanvraag gegevens",
  INTERNE_WERVINGSTEKST: "Interne wervingstekst",
  EXTERNE_WERVINGSTEKST: "Externe wervingstekst",
  WERVING_EN_SELECTIEPROCES: "Werving- & selectieproces",
});

export const AanvraagGegevens = Object.freeze([
  "functie",
  "soortaanvraag",
  "contactgegevens",
  "contractgegevens",
  "akkoordenenadviezen",
  "organisatiegegevens",
  "data",
]);
export const ExcludedSchemaValues = Object.freeze([
  "competentie1",
  "competentie2",
  "competentie3",
  "competentie4",
  "competentie5",
  "competentie6",
]);
export const AanvraagComposites = Object.freeze({
  BEWERKEN: "5b8c3db9-51b9-435a-b18e-0f5fb8fb0c32",
  MOBILITEITSFASE: "faf71a2c-3e78-45e2-96a4-e38ab6dd5e12",
  WERVEN_EN_SELECTEREN: "d2bd5e9c-b93d-4e70-aee5-08a3cd9aaaba",
  AFRONDEN_VERLENGING: "e359fb12-f9e5-4ba8-b71a-c89583439c52",
});

export const MyRequestsTabs = Object.freeze({
  LOPENDE: "Lopende aanvragen",
  ALLE: "Alle aanvragen",
  VERLENGINGEN: "Verlengingen",
});

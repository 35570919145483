import Vue from "vue";
import { SearchServices } from "@/services/SearchServices";
import { ProcessServices } from "@/services/ProcessServices";
import { StoreException } from "@/utils/ErrorHandler";
import { Identifications, Roles } from "@/utils/GeneralConstants";
import { ApplicationsTypes } from "@/utils/GeneralConst/Candidate";

const state = {
  searchServices: null,
  processServices: null,
  myApplications: null,
  pageSize: process.env.VUE_APP_GLOBAL_PAGE_SIZE,
  pageNumber: 1,
  meta: null,
  query: null,

  applicationType: null,
  sorting: null,
};

const getters = {
  searchServices: (state) => state.searchServices,
  processServices: (state) => state.processServices,
  myApplications: (state) => {
    if (!state || !state.myApplications) {
      return null;
    }
    return state.myApplications;
  },
  pageSize: (state) => state.pageSize,
  pageNumber: (state) => state.pageNumber,
  query: (state) => state.query,
  meta: (state) => state.meta,
  applicationType: (state) => state.applicationType,
  sorting: (state) => state.sorting,
};

const actions = {
  /**
   * Internal action for initializing and returning the SearchServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.searchServices|(function(*): (null|getters.searchServices|(function(*))))|null>}
   */
  getSearchServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.searchServices) {
        commit(
          "SET_SEARCH_SERVICES",
          new SearchServices(rootGetters.organisationId)
        );
      }

      return getters.searchServices;
    } catch (error) {
      throw new StoreException("getSearchServices: " + error.message);
    }
  },

  /**
   * Updates the internal services with a new organisation id, if the internal services have already
   * been created. Otherwise the organisation id will be set on creation.
   *
   * @param dispatch
   * @param rootGetters
   * @return {Promise<void>}
   */
  updateSearchServices: ({ getters, rootGetters }) => {
    try {
      const searchServices = getters.searchServices;
      if (searchServices) {
        searchServices.setOrganisation(rootGetters.organisationId);
      }
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Internal action for initializing and returning the ProcessServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.processServices|(function(*): (null|getters.processServices|(function(*))))|null>}
   */
  getProcessServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.processServices) {
        commit(
          "SET_PROCESS_SERVICES",
          new ProcessServices(rootGetters.organisationId)
        );
      }

      return getters.processServices;
    } catch (error) {
      throw new StoreException("getProcessServices: " + error.message);
    }
  },

  /**
   * Updates the internal services with a new organisation id, if the internal services have already
   * been created. Otherwise the organisation id will be set on creation.
   *
   * @param dispatch
   * @param rootGetters
   * @return {Promise<void>}
   */
  updateProcessServices: ({ getters, rootGetters }) => {
    try {
      const processServices = getters.processServices;
      if (processServices) {
        processServices.setOrganisation(rootGetters.organisationId);
      }
    } catch (error) {
      console.error(error);
    }
  },

  /**
   * Configure the query to fetch specific applications
   * @param rootGetters
   * @param getters
   * @param commit
   * @param refresh
   * @returns {any}
   */
  configureQuery: ({ rootGetters, getters, commit }, refresh = false) => {
    if (getters.query && !refresh) {
      return getters.query;
    }

    const query = {
      context: [
        {
          role: Roles.KANDIDAAT_HOUDER,
          instance_identifiers: [rootGetters.userId],
          direction: "from",
          and_or: "OR",
          start: new Date(),
        },
        {
          role: Roles.VOORRANGSKANDIDAAT_HOUDER,
          instance_identifiers: [rootGetters.userId],
          direction: "from",
          and_or: "OR",
          start: new Date(),
        },
      ],
      definitions: [
        Identifications.KANDIDAAT,
        Identifications.VOORRANGSKANDIDAAT,
      ],
    };

    const applicationType = getters.applicationType
      ? getters.applicationType
      : ApplicationsTypes.ALLE.label;
    let statuses;

    if (applicationType === ApplicationsTypes.ALLE.label) {
      statuses = [];
    } else if (applicationType === ApplicationsTypes.LOPEND.label) {
      statuses = ApplicationsTypes.LOPEND.statuses.map((statusName) => {
        return { status: statusName, and_or: "OR" };
      });
    } else if (applicationType === ApplicationsTypes.AFGEROND.label) {
      statuses = ApplicationsTypes.AFGEROND.statuses.map((statusName) => {
        return { status: statusName, and_or: "OR" };
      });
    }
    Vue.set(query, "statuses", statuses);

    if (getters.sorting) {
      Vue.set(query, "sorting", [
        {
          sort_type: getters.sorting.type,
          direction: getters.sorting.direction,
        },
      ]);
    }
    commit("SET_QUERY", query);
    return query;
  },

  /**
   * Retrieves open applications assigned to a logged user
   * @param getters
   * @param commit
   * @param dispatch
   * @param refresh
   * @returns {Promise<void>}
   */
  retrieveMyApplications: async (
    { getters, commit, dispatch },
    refresh = false
  ) => {
    const searchServices = await dispatch("getSearchServices");
    try {
      await dispatch("configureQuery", refresh);

      const { data, meta } = await searchServices.postInstanceQuery(
        getters.pageSize,
        getters.pageNumber,
        getters.query,
        false
      );
      commit("SET_MY_APPLICATIONS", data);
      commit("SET_META", meta);
    } catch (error) {
      console.error(error);

      throw new StoreException(
        "Something went wrong while retrieving my open applications"
      );
    }
  },

  /**
   * Withdraws a given application
   * @param dispatch
   * @param instanceId
   * @returns {Promise<void>}
   */
  startAndExecuteIntrekkenSollicitatie: async ({ dispatch }, instanceId) => {
    const processServices = await dispatch("getProcessServices");
    try {
      await processServices.startAndExecuteStage(
        instanceId,
        Identifications.STAGE_DEFINITION_INTREKKEN_SOLLICITATIE,
        {
          zekerweten: true,
        }
      );
    } catch (error) {
      throw new StoreException(
        "Something went wrong while retrieving my open applications"
      );
    }
  },

  /**
   * Sets the page number
   * @param commit
   * @param dispatch
   * @param pageNumber
   * @returns {Promise<void>}
   */
  setPageNumber: async ({ commit, dispatch }, pageNumber) => {
    commit("SET_PAGE_NUMBER", pageNumber);
    await dispatch("retrieveMyApplications");
  },

  /**
   * Sets the type of the applications that indicates the statuses of the applications
   * @param commit
   * @param status
   * @returns {Promise<void>}
   */
  setApplicationType: async ({ commit }, applicationType) => {
    commit("SET_APPLICATION_TYPE", applicationType);
  },

  /**
   * Sets sorting
   * @param commit
   * @param sorting
   * @returns {Promise<void>}
   */
  setSorting: async ({ commit }, sorting) => {
    if (sorting && sorting.type) {
      if (!sorting.direction) {
        sorting.direction = "DESCENDING";
      }
      commit("SET_SORTING", sorting);
    } else {
      commit("SET_SORTING", null);
    }
  },
};

const mutations = {
  SET_SEARCH_SERVICES: (state, payload) =>
    Vue.set(state, "searchServices", payload),
  SET_PROCESS_SERVICES: (state, payload) =>
    Vue.set(state, "processServices", payload),
  SET_MY_APPLICATIONS: (state, payload) =>
    Vue.set(state, "myApplications", payload),
  SET_META: (state, payload) => Vue.set(state, "meta", payload),

  SET_PAGE_NUMBER: (state, payload) => Vue.set(state, "pageNumber", payload),
  SET_PAGE_SIZE: (state, payload) => Vue.set(state, "pageSize", payload),
  SET_QUERY: (state, payload) => Vue.set(state, "query", payload),

  SET_APPLICATION_TYPE: (state, payload) =>
    Vue.set(state, "applicationType", payload),
  SET_SORTING: (state, payload) => Vue.set(state, "sorting", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

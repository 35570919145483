import { ClassException } from "@/utils/ErrorHandler";
import IdUtils from "@/utils/IdUtils";

const SEARCH_FIELDS = [
  "NAME",
  "CONTENT",
  "STATUS",
  "IDENTIFICATION",
  "IDENTIFIER",
];

/***
 * Creates a new Query instance based on input
 *
 * @class
 * @typedef {Object} Query
 * @param {Content[]} [content] - An optional array filled with Content-items
 * @param {Context[]} [context] - An optional array filled with Context-items
 * @param {Status[]} [statuses] - An optional array filled with Status-items
 * @param {string[]} [definitions] - An optional array filled with instance definitions
 * @param {string[]} [definitions] - An optional array filled with instance definitions
 * @param {Sorting[]} [sorting] - An optional array filled with Sorting-items
 * @param {TextSearch[]} [text_search] - An optional array filled with TextSearch-items
 */
export class Query {
  constructor({
    content,
    context,
    statuses,
    definitions,
    sorting,
    text_search,
    timestamps,
  }) {
    this.content =
      content && content.length && Array.isArray(content)
        ? content.map((item) => new Content(item))
        : [];
    this.context =
      context && Array.isArray(context)
        ? context.map((item) => new Context(item))
        : [];
    this.statuses =
      statuses && Array.isArray(statuses)
        ? statuses.map((item) => new Status(item))
        : [];
    this.definitions = definitions ? definitions : [];
    this.sorting =
      sorting && Array.isArray(sorting)
        ? sorting.map((item) => new Sorting(item))
        : [
            { sort_type: "text_score", direction: "DESCENDING" },
            { sort_type: "timestamp_created", direction: "DESCENDING" },
          ];
    this.text_search =
      text_search && Array.isArray(text_search)
        ? text_search.map((item) => new TextSearch(item))
        : [];
    if (Array.isArray(timestamps) && timestamps.length) {
      this.timestamps = timestamps.map((item) => new Timestamp(item));
    } else {
      this.timestamps = [];
    }
  }
}

/***
 * Creates a new Content instance based on input
 *
 * @class
 * @typedef {Object} Content
 * @param {string} key - A required param that should contain the key to filter
 * @param {string} [datatype] - A optional param that defines the type of data to be filtered on.
 * @param {String[]} [values] - An optional array filled with the values to filter on.
 * @param {Object.<Date, string>} [period] - An optional object that, if provided, should contain a comparator and timestamp.
 * @param {string} [and_or] - An optional param that should be one of these values: "AND", "OR", null, undefined
 *
 * @example
 * new Content({
 *    key: "functie-werktitel-display-value",
 *    datatype: "STRING",
 *    values: [
 *      "bestekschrijver",
 *      "Communicatiemedewerker"
 *    ]
 * });
 *
 * @example
 * new Content({
 *    key: "data-startontvangen",
 *    datatype: "DATE",
 *    period: {
 *      comparator: "LT",
 *      timestamp: "2021-10-31T23:00:00.000Z", "2021-12-01T22:59:59.000Z"
 *    }
 * });
 */
export class Content {
  constructor({
    key,
    datatype,
    values,
    period,
    and_or,
    comparator,
    timestamp_value,
  }) {
    if (!key) {
      throw new ClassException(
        "Content instance cannot be created without key"
      );
    }
    if (and_or && and_or !== "AND" && and_or !== "OR") {
      throw new ClassException(
        'Content instance expects and_or param to be one of the following: "AND", "OR", null, undefined. Currently value is: ' +
          and_or
      );
    }
    if (period && !period.timestamp && !period.comparator) {
      throw new ClassException(
        "Content instance expects period param to contain a timestamp and a comparator."
      );
    }
    if (values && !Array.isArray(values)) {
      throw new ClassException(
        "Content instance expects values param to be of type Array."
      );
    }

    this.key = key;
    this.and_or = and_or ? and_or : "AND";
    this.datatype = datatype ? datatype : "STRING";

    if (period?.timestamp && period?.comparator) {
      this.comparator = period.comparator;
      this.timestamp_value = period.timestamp;
    } else if (timestamp_value && comparator) {
      this.comparator = comparator;
      this.timestamp_value = timestamp_value;
    } else {
      this.values = values ? values : [];
    }
  }
}

/***
 * Creates a new Context instance based on input
 *
 * @class
 * @typedef {Object} Context
 * @param {string} role - A required param that should contain the role-name of the context
 * @param {String[]} [instance_identifiers] - An optional array filled with the instance identifications to filter on.
 * @param {string} [direction] - A optional param that defines the directions of the relation.
 * @param {string} [and_or] - An optional param that should be one of these values: "AND", "OR", null, undefined
 *
 * @example
 * new Context({
 *    role: "Aanvrager",
 *    instance_identifiers: ["2c0aeaa4-6d18-4dbb-ae91-cba4425e0f8d"],
 *    direction: "from"
 * });
 */
export class Context {
  constructor({ role, instance_identifiers, direction, and_or }) {
    if (!role) {
      throw new ClassException("Context cannot be created without role");
    }
    if (instance_identifiers && !Array.isArray(instance_identifiers)) {
      throw new ClassException(
        "Context instance expects instance_identifiers param to be of type Array."
      );
    }
    if (and_or && and_or !== "AND" && and_or !== "OR") {
      throw new ClassException(
        'Context instance expects and_or param to be one of the following: "AND", "OR", null, undefined. Currently value is: ' +
          and_or
      );
    }
    if (direction && direction !== "from" && direction !== "to") {
      throw new ClassException(
        'Context instance expects direction param to be one of the following: "from", "to", null, undefined. Currently value is: ' +
          direction
      );
    }

    this.role = role;
    this.start = new Date();
    this.instance_identifiers = instance_identifiers
      ? instance_identifiers
      : [];
    this.direction = direction ? direction : "from";
    this.and_or = and_or ? and_or : "OR";
  }
}

export class Timestamp {
  constructor({ type, timestamp_from, timestamp_to, and_or = "AND" }) {
    if (!type) {
      throw new ClassException(
        "Timestamp instance expects type param to contain a timestamp type"
      );
    }
    if (!timestamp_from) {
      throw new ClassException(
        "Timestamp instance expects timestamp_from param to contain a timestamp"
      );
    }

    this.and_or = and_or;
    this.type = type;
    this.timestamp_from = new Date(timestamp_from).toISOString();

    if (timestamp_to) {
      this.timestamp_to = new Date(timestamp_to).toISOString();
    }
  }
}

/***
 * Creates a new Status instance based on input
 *
 * @class
 * @typedef {Object} Status
 * @param {string} status - A required param that should contain the status-value
 * @param {string} [and_or] - An optional param that should be one of these values: "AND", "OR", null, undefined
 *
 * @example
 * new Status({
 *    status: "concept",
 * });
 */
export class Status {
  constructor({ status, and_or }) {
    if (!status) {
      throw new ClassException("Status cannot be created without status");
    }
    if (and_or && and_or !== "AND" && and_or !== "OR") {
      throw new ClassException(
        'Status instance expects and_or param to be one of the following: "AND", "OR", null, undefined. Currently value is: ' +
          and_or
      );
    }

    this.status = status;
    this.and_or = and_or ? and_or : "OR";
  }
}

/***
 * Creates a new Sorting instance based on input
 *
 * @class
 * @typedef {Object} Sorting
 * @param {string} sort_type - A required param that should contain the sort-type. Sort_type needs to be one of the following:
 *                             "name", "identifier", "identification", "timestamp_created", "last_updated_timestamp", "content", "status"
 * @param {string} [direction] - A optional param that defines the sorting direction. Needs to be one of the following:
 *                               "ASCENDING", "DESCENDING"
 * @param {string} [field] - An semi-required param that should be submitted if sort_type === "content"
 *
 * @example
 * new Sorting({
 *    sort_type: "text_score",
 *    direction: "DESCENDING"
 * });
 *
 * @example
 * new Sorting({
 *    sort_type: "content",
 *    direction: "DESCENDING",
 *    field: "functie-vakgebied"
 * });
 */
export class Sorting {
  constructor({ sort_type, direction, field }) {
    if (!sort_type) {
      throw new ClassException("Sorting cannot be created without sort_type");
    }
    if (direction && direction !== "ASCENDING" && direction !== "DESCENDING") {
      throw new ClassException(
        'Sorting instance expects direction param to be one of the following: "ASCENDING", "DESCENDING", null, undefined. Currently value is: ' +
          direction
      );
    }
    if (sort_type === "content" && !field) {
      throw new ClassException(
        "Sorting instance cannot be created without field if sort_type is content"
      );
    }

    this.sort_type = sort_type;
    this.direction = direction ? direction : "ASCENDING";

    if (sort_type === "content") {
      this.field = field;
    }
  }
}

/***
 * Creates a new TextSearch instance based on input
 *
 * @class
 * @typedef {Object} TextSearch
 * @param {string} text_query - A required param that should contain the text to search on.
 * @param {string} [and_or] - An optional param that should be one of these values: "AND", "OR", null, undefined
 * @param {boolean} [fuzzy] - An optional param that determines if fuzzy search is needed.
 * @param {string[]} [search_fields] - An optional param that defines where text-search should be done.
 *
 * @example
 * new TextSearch({
 *    text_query: "Test",
 *    fuzzy: true
 * });
 */
export class TextSearch {
  constructor({ text_query, and_or, fuzzy, search_fields }) {
    if (!text_query) {
      throw new ClassException(
        "Text search cannot be created without text_query"
      );
    }
    if (and_or && and_or !== "AND" && and_or !== "OR") {
      throw new ClassException(
        'TextSearch instance expects and_or param to be one of the following: "AND", "OR", null, undefined. Currently value is: ' +
          and_or
      );
    }
    if (fuzzy && typeof fuzzy !== "boolean") {
      throw new ClassException(
        "TextSearch instance expects fuzzy param to be an boolean. Currently value is of type " +
          typeof and_or
      );
    }
    if (search_fields && !Array.isArray(search_fields)) {
      throw new ClassException(
        "TextSearch instance expects search_fields param to be of type Array."
      );
    }
    if (
      search_fields &&
      !search_fields.every((field) => SEARCH_FIELDS.includes(field))
    ) {
      throw new ClassException(
        "TextSearch instance expects search_fields to be one of the following:" +
          SEARCH_FIELDS +
          "."
      );
    }

    this.text_query = text_query;
    this.and_or = and_or ? and_or : "AND";
    this.fuzzy = fuzzy ? fuzzy : true;
    this.search_fields = search_fields
      ? search_fields
      : ["NAME", "CONTENT", "IDENTIFIER", "STATUS"];
  }
}

export class InstanceQuery extends Query {
  constructor(instanceIdentification) {
    if (!IdUtils.isUUID(instanceIdentification)) {
      throw new ClassException(
        "Invalid identification to create InstanceQuery"
      );
    }

    super({});
    this.instance_identifications = [instanceIdentification];
  }
}

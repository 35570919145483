import { Identifications, Roles } from "@/utils/GeneralConstants";

export const searchProfileQuery = {
  context: [
    {
      role: Roles.TALENT_PROFIEL_HOUDER,
      instance_identifiers: null,
      direction: "from",
      and_or: "OR",
      start: new Date().toISOString(),
    },
  ],
  definitions: [Identifications.TALENT_PROFIEL],
};

export const ProfileOwnerActions = Object.freeze({
  CONTROLEREN_EN_INDIENEN: "0f3dfc7b-c9c0-45a8-b16b-d3de31191b81",
});

export const TalentProfileTypes = Object.freeze({
  interne_medewerker: "Interne medewerker",
  interne_kandidaat: "Interne kandidaat",
  externe_kandidaat: "Externe kandidaat",
  voorrangskandidaat: "Voorrangskandidaat",
  externe_voorrangskandidaat: "Externe voorrangskandidaat",
});

export const TalentProfileStatuses = Object.freeze({
  NIEUW: "NIEUW",
  GEWIJZIGD: "GEWIJZIGD",
  ACTIEF: "ACTIEF",
  TER_AKKOORD: "TER AKKOORD",
  AANVULLING_NODIG: "AANVULLING NODIG",
  INACTIEF: "INACTIEF",
  VERLOPEN: "VERLOPEN",
});

export const ProfileActionsLBA = Object.freeze({
  ALGEMEEN: "e79cbc77-439e-49dd-b786-9d90805c6565",
  OMSCHRIJVING: "475da0d4-c4bf-4976-bde6-73c626834ae4",
  WERKERVARING: "fd1f6875-0125-441c-8bc6-7f33fb55d05e",
  OPLEIDINGEN: "ebf0a961-3565-4e22-942e-c801a37e794a",
  CERTIFICATEN_EN_CURSUSSEN: "ee9287a8-b80d-4e2c-afb9-053aa019de7e",
  EXPERTISES: "f40b6350-5704-4571-8257-f8169b804e1b",
  TALEN_EN_RIJBEWIJS: "bfc8782d-e691-49ec-bda6-619d7353264d",
  COMPETENTIES: "f8925693-2793-416c-92af-a42f6edac2ff",
  ZOEKRICHTING: "8e07e510-cdbf-4c99-a8ca-19c791bcb231",
  BESCHIKBAARHEID: "ff697099-9639-4f72-81ae-9a3a3ef308d5",
});
export const ProfileActionsOwner = Object.freeze({
  ALGEMEEN: "563a12c9-46ef-42ed-b150-ea00fd67f450",
  OMSCHRIJVING: "69b37c2c-3b0a-4aa2-b73b-527939fe7f41",
  WERKERVARING: "db8a2ba3-61ec-449a-bdcf-354352c01678",
  OPLEIDINGEN: "63b79b5f-f441-4f60-8493-6f3bae909db9",
  CERTIFICATEN_EN_CURSUSSEN: "1a40835f-3095-4fa7-af77-43d67e7b6878",
  EXPERTISES: "b6041415-eaaf-437c-b9e7-79af10ff4e85",
  TALEN_EN_RIJBEWIJS: "daedcee4-fac8-4b38-99a2-4b1691701d23",
  COMPETENTIES: "bd742690-7129-4393-973f-e97ad0afc0a0",
  ZOEKRICHTING: "1f2e263c-2230-4461-8312-1668a28f8085",
  BESCHIKBAARHEID: "b1c5177f-d6dd-4f03-aaec-5e2952034415",
});

export const TalentProfileActions = Object.freeze({
  PROFIELOPSTELLEN: "2be18ed8-8164-4a93-96d5-9b5c5aa3f5f6",
  INDIENENPROFIEL: "0f3dfc7b-c9c0-45a8-b16b-d3de31191b81",
  INDIENENWIJZIGINGEN: "193a81b4-b2f1-4a51-8ee5-d3a063116fcc",
  ACTIVERENPROFIEL: "ee0a21be-7932-4dbe-bba0-e5adcbdf46f5",
  DEACTIVERENPROFIEL: "9b0b8d2d-fdc3-49ec-9240-987d8dc36161",
});

export const ProfileCategories = Object.freeze({
  BOVENTALLIG: "Boventallig",
  REINTEGRATIE: "Re-integratie",
  ADMINISTRATIEF_GEPLAATST: "Administratief geplaatst",
  PREBOVENTALLIG: "Pre-boventallig",
  INTERNE_MEDEWERKER: "Interne medewerker",
  UITZENDKRACHT: "Uitzendkracht",
  TRAINEE: "Trainee",
  WW: "WW",
  EXTERNE_SOLLICITANT: "Lager onderwijs",
  OUDSTAGIAIRE: "Oud-stagiaire",
  ARBEIDSBEPERKING: "Arbeidsbeperking",
});

export const ProfileWizardTitles = Object.freeze({
  gegevensophalenuitmijnpo: "MijnP&O koppeling",
  algemeneinformatie: "algemene gegevens",
  omschrijving: "omschrijving",
  werkervaring: "werkervaring",
  opleidingen: "opleidingen",
  certificatenencursussen: "certificaten en cursussen",
  expertises: "expertises",
  talenenrijbewijs: "talen en rijbewijs",
  competenties: "competenties",
  infoloopbaan: "loopbaan",
  zoekrichting: "zoekrichting",
  beschikbaarheid: "beschikbaarheid",
});

export const ProfileSectionsLabels = Object({
  GENERAL: "ALGEMEEN",
  DESCRIPTION: "OMSCHRIJVING",
  WORK_EXPERIENCE: "WERKERVARING",
  EDUCATION: "OPLEIDINGEN",
  CERTIFICATES: "CERTIFICATEN_EN_CURSUSSEN",
  LANGUAGES: "TALEN_EN_RIJBEWIJS",
  COMPETENCIES: "COMPETENTIES",
  EXPERTISES: "EXPERTISES",
  SEARCH: "ZOEKRICHTING",
  AVAILABILITY: "BESCHIKBAARHEID",
});

export const ShowItemsAsRecords = ["Werkervaring"];

import Vue from "vue";
import Vuex from "vuex";

/* Plugins */
import merge from "deepmerge";

/* Utils */
import { StoreException } from "@/utils/ErrorHandler";

/* Constants */
import {
  AccessDatabaseProfielen,
  AccessMijnActies,
  AccessMijnLoopbaanadvies,
  AccessMijnRecruitment,
  AccessMijnTalenten,
  AccessMijnWervingEnSelectie,
  AllUserRoles,
} from "@/utils/UserRoles";

/* Services */
import { UserServices } from "@/services/security/UserServices";

/* Store Modules */
import tenantStore from "@/store/tenant";
import omniselect from "@/components/omnischema/omniselect/store";
import instanceDefinitions from "@/store/instanceDefinitions";
import genericInstance from "@/store/genericInstance";
import search from "@/store/search";
import activity from "@/store/activity";
import instanceFlow from "@/store/instanceFlow";
import notifications from "@/store/notifications";
import chat from "@/store/chat";
import media from "@/store/media";
import talentProfile from "@/store/talentProfile";
import requests from "@/store/requests";
import candidates from "@/store/candidates";
import loopbaanadvies from "@/store/loopbaanadvies";
import applications from "@/store/applications";
import processStore from "@/store/process";
import recruitment from "@/store/recruitment";
import matchbox from "@/store/matchbox";
import { checkIfUserHasThisRole } from "@/utils/GeneralUtils";
import IdUtils from "@/utils/IdUtils";
import {
  DeeplinkActions,
  Identifications,
  RoutesNames,
  Roles,
} from "@/utils/GeneralConstants";
import { Links } from "@/utils/Links";
import recruitmentText from "@/store/recruitmentText";
import configurations from "@/store/configurations";
import emailTemplates from "@/store/emailTemplates";
import breadcrumbs from "@/store/breadcrumbs";
import mapping from "@/store/mapping";
import conceptDefinitions from "@/store/conceptDefinitions";
import talentPoolProfile from "@/store/talentPoolProfile";

require("es6-promise").polyfill();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userServices: null,

    tenant: {
      identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
    },

    organisation: {
      identification: null,
    },

    user: {
      identification: "",
      profile: {},
      organisations: {
        mandate_organisations: [],
        own_organisations: [],
      },
    },

    controleerAanvragen: false,

    canAccessMijnRecruitment: null,
    canAccessMijnWervingEnSelectie: null,
    canAccessMijnLoopbaanadvies: null,
    canAccessDatabaseProfielen: null,
    canAccessMijnTalenten: null,
    canAccessMijnActies: null,
    canAccessFaq: null,
    isAccessChecked: false,
  },

  getters: {
    userServices: (state) => state.userServices,

    tenant: (state) => state.tenant,
    tenantId: (state) => state.tenant.identification,

    organisation: (state) => state.organisation,
    organisationId: (state) => state.organisation.identification,

    user: (state) => state.user,
    userId: (state) => state.user.identification,
    userName: (state) => state.user.alias,
    userOrganisations: (state) => state.user.organisations,
    userOwnOrganisations: (state) => state.user.organisations.own_organisations,
    userMandateOrganisations: (state) =>
      state.user.organisations.mandate_organisations,

    controleerAanvragen: (state) => state.controleerAanvragen,

    canAccessMijnRecruitment: (state) => state.canAccessMijnRecruitment,
    canAccessMijnWervingEnSelectie: (state) =>
      state.canAccessMijnWervingEnSelectie,
    canAccessMijnLoopbaanadvies: (state) => state.canAccessMijnLoopbaanadvies,
    canAccessDatabaseProfielen: (state) => state.canAccessDatabaseProfielen,
    canAccessMijnTalenten: (state) => state.canAccessMijnTalenten,
    canAccessMijnActies: (state) => state.canAccessMijnActies,
    canAccessFaq: (state) => state.canAccessFaq,
    isAccessChecked: (state) => state.isAccessChecked,

    isUserAdmin: (state) =>
      checkIfUserHasThisRole(state.user, AllUserRoles.ADMINISTRATOR) ||
      checkIfUserHasThisRole(state.user, AllUserRoles.ADMIN),
    isUserManager: (state) =>
      checkIfUserHasThisRole(state.user, AllUserRoles.MANAGER),
    isUserLBA: (state) =>
      checkIfUserHasThisRole(state.user, AllUserRoles.LOOPBAAN_ADVISEUR),
    isUserBackoffice: (state) =>
      checkIfUserHasThisRole(state.user, AllUserRoles.BACKOFFICE_MEDEWERKER),
    isUserRecruiter: (state) =>
      checkIfUserHasThisRole(state.user, AllUserRoles.RECRUITER),
    isUserStandaardGebruiker: (state) =>
      checkIfUserHasThisRole(state.user, AllUserRoles.STANDAARD_GEBRUIKER),
  },

  actions: {
    /**
     * Function to create the UserServices for the known tenant id.
     * Throws a StoreException on missing tenant id.
     * @param commit
     * @param getters
     * @return {getters.userServices|(function(*): (null|getters.userServices|(function(*))))|null}
     */
    getUserServices: ({ commit, getters }) => {
      if (!getters.userServices) {
        if (!getters.tenantId) {
          throw new StoreException("No tenant known");
        } else {
          commit("SET_USER_SERVICES", new UserServices());
        }
      }
      return getters.userServices;
    },

    /**
     * Updates the internal services with a new organisation id, if the internal services have already
     * been created. Otherwise the organisation id will be set on creation.
     * @param dispatch
     * @param rootGetters
     * @return {Promise<void>}
     */
    updateUserServices: ({ getters, rootGetters }) => {
      const userServices = getters.userServices;
      if (userServices) {
        userServices.setOrganisation(rootGetters.organisationId);
      }
    },

    setTenantId: ({ commit }) => {
      commit("SET_TENANT", {
        identification: process.env.VUE_APP_TENANT_IDENTIFICATION,
      });
    },

    /**
     * Function which fetches the tenant for the given tenant id, and commits it to the store.
     * @param getters
     * @param commit
     * @param dispatch
     * @return {Promise<void>}
     */
    fetchTenant: async ({ commit, dispatch }) => {
      await dispatch("setTenantId", process.env.VUE_APP_TENANT_IDENTIFICATION);

      const tenantServices = await dispatch("tenantStore/getTenantServices");

      commit("SET_TENANT", await tenantServices.get());
    },

    /**
     * Fetches the identity providers of the active tenant.
     * @param dispatch
     * @return {Promise<void>}
     */
    fetchIdentityProviders: async ({ dispatch }) => {
      const userServices = await dispatch("getUserServices");
      try {
        const idProviders = await userServices.getIdentityProviders();

        // Filter out unsupported id providers
        return idProviders.filter(
          (provider) => provider.openid_connect && provider.source === "MSLIVE"
        );
      } catch (e) {
        throw new StoreException(e.message);
      }
    },

    fetchIdentityProvider: async ({ dispatch }, identification) => {
      if (!IdUtils.isUUID(identification)) {
        throw new StoreException("Please provide a valid identification");
      }

      const userServices = await dispatch("getUserServices");
      try {
        return await userServices.getIdentityProvider(identification);
      } catch (e) {
        throw new StoreException(e.message);
      }
    },

    /**
     * Check the user registration
     * @param dispatch
     * @returns {Promise<any>}
     */
    checkRegistration: async ({ dispatch }) => {
      const userServices = await dispatch("getUserServices");
      try {
        return await userServices.checkUserRegistration();
      } catch (e) {
        throw new StoreException(e.message);
      }
    },

    /**
     * Register a user
     * @param dispatch
     * @returns {Promise<*>}
     */
    registerUser: async ({ dispatch }) => {
      const userServices = await dispatch("getUserServices");
      try {
        return await userServices.registerUser();
      } catch (e) {
        throw new StoreException(e.message);
      }
    },

    /**
     * Check if the invitation key is valid
     * @param dispatch
     * @param invitationKey
     * @returns {Promise<*>}
     */
    checkInvitationKey: async ({ dispatch }, invitationKey) => {
      if (!invitationKey) {
        throw new StoreException("Invitation key is missing");
      }
      const userServices = await dispatch("getUserServices");
      try {
        return await userServices.checkInvitationKey(invitationKey);
      } catch (e) {
        throw new StoreException(e.message);
      }
    },

    /**
     * Fetches all organisations the currently logged in user is authorised to.
     * When only 1 organisation is found, this organisation is also selected.
     * @param commit
     * @param dispatch
     * @return {Promise<void>}
     */
    fetchUserOrganisations: async ({ commit, dispatch }) => {
      const userServices = await dispatch("getUserServices");

      let errors = false;

      try {
        // Get user organisations
        const userOrganisations = await userServices.getUserOrganisations();

        if (userOrganisations) {
          // Add the user organisations to the store
          commit("UPDATE_USER", { organisations: userOrganisations });

          // Select the only organisation if there is only 1
          if (
            "mandate_organisations" in userOrganisations &&
            "own_organisations" in userOrganisations
          ) {
            if (
              userOrganisations.mandate_organisations.length === 1 &&
              userOrganisations.own_organisations.length === 0
            ) {
              await dispatch(
                "selectUserOrganisation",
                userOrganisations.mandate_organisations[0].identification
              );
            } else if (
              userOrganisations.mandate_organisations.length === 0 &&
              userOrganisations.own_organisations.length === 1
            ) {
              await dispatch(
                "selectUserOrganisation",
                userOrganisations.own_organisations[0].identification
              );
            }
          }
        } else {
          // On no organisations found, create an error
          errors = new StoreException("No organisations for the current user");
        }
      } catch (e) {
        throw new StoreException(e.message);
      }

      if (errors) {
        throw errors;
      }
    },

    /**
     * Function to activate the currently validated user with an invitation key.
     * @param dispatch
     * @param invitationKey
     * @return {Promise<*>}
     */
    activateUser: async ({ dispatch }, invitationKey) => {
      try {
        const userServices = await dispatch("getUserServices");

        return await userServices.postActivateUser(invitationKey);
      } catch (e) {
        throw new StoreException(e.getMessage());
      }
    },

    /**
     * Select and set the organisation based on an organisation identification
     * @param commit
     * @param dispatch
     * @param getters
     * @param organisationId
     * @return {Promise<void>}
     */
    selectUserOrganisation: async (
      { commit, dispatch, getters },
      organisationId
    ) => {
      // Check if the given organisation id is present in the user organisations
      let organisation = false;
      const foundOwnOrganisation = getters.userOwnOrganisations.find(
        (userOrg) => userOrg.identification === organisationId
      );
      if (foundOwnOrganisation) {
        organisation = foundOwnOrganisation;
      } else {
        const foundMandateOrganisation = getters.userMandateOrganisations.find(
          (userOrg) => userOrg.identification === organisationId
        );
        if (foundMandateOrganisation) {
          organisation = foundMandateOrganisation;
        }
      }

      // If so, set the found organisation as the current organisation
      if (organisation) {
        commit("SET_ORGANISATION", organisation);
        await dispatch("updateUserServices");

        // Get user profile for organisation
        await dispatch("fetchUser");

        // Update services
        await Promise.all([
          dispatch("tenantStore/updateTenantServices"),
          dispatch("instanceDefinitions/updateInstanceDefinitionServices"),
        ]);
      } else {
        // If not, throw error
        throw new StoreException("Invalid organisation id received.");
      }
    },

    /**
     * Fetches the user profile of the currently logged in user, and commits it to the store.
     * @param commit
     * @param dispatch
     * @param getters
     * @return {Promise<void>}
     */
    fetchUser: async ({ commit, dispatch }) => {
      const userServices = await dispatch("getUserServices");

      const user = await userServices.getUserProfile();

      commit("UPDATE_USER", user);
    },

    /**
     * Set user's access rights to certain website sections based on the user's roles.
     * @param commit
     * @param getters
     * @param userRelationships
     * @returns {Promise<void>}
     */
    setUserAccessRights: async ({ commit, getters }, userRelationships) => {
      if (!getters.isAccessChecked) {
        const userRoles = userRelationships.roles.map(
          (role) => role.role.identification
        );

        const canAccessMijnRecruitment = userRoles.some((role) =>
          AccessMijnRecruitment.includes(role)
        );
        const canAccessMijnWervingEnSelectie = userRoles.some((role) =>
          AccessMijnWervingEnSelectie.includes(role)
        );
        const canAccessMijnLoopbaanadvies = userRoles.some((role) =>
          AccessMijnLoopbaanadvies.includes(role)
        );
        const canAccessDatabaseProfielen = userRoles.some((role) =>
          AccessDatabaseProfielen.includes(role)
        );
        const canAccessMijnTalenten = userRoles.some((role) =>
          AccessMijnTalenten.includes(role)
        );
        const canAccessMijnActies = userRoles.some((role) =>
          AccessMijnActies.includes(role)
        );
        const canAccessFaq = userRoles.includes(
          AllUserRoles.STANDAARD_GEBRUIKER
        );

        commit("CAN_ACCESS_MIJN_RECRUITMENT", canAccessMijnRecruitment);
        commit(
          "CAN_ACCESS_MIJN_WERVING_EN_SELECTIE",
          canAccessMijnWervingEnSelectie
        );
        commit("CAN_ACCESS_MIJN_LOOPBAANADVIES", canAccessMijnLoopbaanadvies);
        commit("CAN_ACCESS_DATABASE_PROFIELEN", canAccessDatabaseProfielen);
        commit("CAN_ACCESS_MIJN_TALENTEN", canAccessMijnTalenten);
        commit("CAN_ACCESS_MIJN_TAKEN", canAccessMijnActies);
        commit("CAN_ACCESS_FAQ", canAccessFaq);
        commit("IS_ACCESS_CHECKED", true);
      }
    },

    /**
     *
     * @param dispatch
     * @param getters
     * @param instanceId
     * @return {Promise<{params: {}}>}
     */
    determineInstanceRoute: async ({ dispatch, getters }, instanceId) => {
      const route = { name: "", params: {} };

      // Handle routing to a non-dynamic path
      // instanceId is not an actual instance ID but it includes a string that determines the route
      if (!IdUtils.isUUID(instanceId)) {
        // If the input is not an ID, it should be a known action
        if (Object.values(DeeplinkActions).includes(instanceId)) {
          switch (instanceId) {
            case DeeplinkActions.CREATE_CAPACITY_REQUEST:
              route.name = RoutesNames.NIEUWE_AANVRAAG;
              route.params = Links.NEW;
              break;
            case DeeplinkActions.MY_PROFILE:
              route.name = RoutesNames.PROFIEL;
              route.params = Links.MIJN_PROFIEL;
              break;
            case DeeplinkActions.MY_APPLICATIONS:
              route.name = RoutesNames.SOLLICITATIES;
              route.params = Links.MIJN_SOLLICITATIES;
              break;
            case DeeplinkActions.MY_JOBALERTS:
              route.name = RoutesNames.JOB_ALERT;
              route.params = Links.JOB_ALERT;
              break;
            case DeeplinkActions.RECRUITMENT:
              route.name = RoutesNames.RECRUITMENT;
              route.params = Links.MIJN_RECRUITMENT;
              break;
            case DeeplinkActions.LOOPBAANADVIES:
              route.name = RoutesNames.LOOPBAANADVIES;
              route.params = Links.MIJN_LOOPBAANADVIES;
              break;
            case DeeplinkActions.LOOPBAANADVIES_PROFIELEN:
              route.name = RoutesNames.PROFIELEN;
              route.params = Links.LBA_PROFIELEN;
              break;
            case DeeplinkActions.WERVING_SELECTIE:
              route.name = RoutesNames.WERVINGSELECTIE;
              route.params = Links.MIJN_WERVING_EN_SELECTIE;
              break;
            case DeeplinkActions.WERVING_SELECTIE_ACTIES:
              route.name = RoutesNames.ACTIES;
              route.params = Links.MIJN_TAKEN;
              break;
            default:
              // Do nothing
              break;
          }
          return route;
        }
      }

      // Search for instance
      const instance = await dispatch(
        "search/searchSingleInstance",
        instanceId
      );

      // Check instance definition and user's relation to instance
      switch (instance.relationships.definition.identification) {
        // For a capacity request, always navigate to the Aanvraag page
        case Identifications.AANVRAAG_CAPACITEIT:
          route.name = route.params.name = RoutesNames.AANVRAAG;
          route.params.instanceId = instanceId;
          break;

        case Identifications.TALENT_PROFIEL:
          // For Talent Profiel, check if it is the user's own profile
          if (
            instance.relationships.context.find(
              (context) => context.role === Roles.TALENT_PROFIEL_HOUDER
            ).relationships.related_instance.identification === getters.userId
          ) {
            // Navigate to own profile
            route.name = RoutesNames.PROFIEL;
            delete route.params;
          } else {
            // Navigate to profile in LBA
            route.name = route.params.name = RoutesNames.LBA_PROFIEL;
            route.params.instanceId = instanceId;
            route.params.instanceName = instance.name;
          }
          break;

        case Identifications.KANDIDAAT:
          // Find the related capacity request of the candidate, and fill shared route params
          const relatedRequest = instance.relationships.context.find(
            (context) =>
              context.role === Roles.INTERNE_KANDIDAAT ||
              context.role === Roles.EXTERNE_KANDIDAAT
          );
          route.params = {
            instanceId:
              relatedRequest.relationships.related_instance.identification,
            instanceName: relatedRequest.relationships.related_instance.name,
            candidateId: instanceId,
            candidateName: instance.name,
          };

          // If this is the user's own application candidate instance, navigate to application details
          if (
            instance.relationships.context.find(
              (context) => context.role === Roles.KANDIDAAT_HOUDER
            ).relationships.related_instance.identification === getters.userId
          ) {
            route.name = route.params.name = RoutesNames.SOLLICITATIE_DETAILS;
          } else {
            // Else, navigate to candidate route within the capacity request
            route.name = route.params.name = RoutesNames.KANDIDAAT;
          }
          break;

        case Identifications.VOORRANGSKANDIDAAT:
          // Find the related capacity request of the priority candidate, and fill shared route params
          const relatedPriorityRequest = instance.relationships.context.find(
            (context) => context.role === Roles.VOORRANGSKANDIDAAT
          );
          route.params = {
            instanceId:
              relatedPriorityRequest.relationships.related_instance
                .identification,
            instanceName:
              relatedPriorityRequest.relationships.related_instance.name,
            candidateId: instanceId,
            candidateName: instance.name,
          };

          // If this is the user's own application prioritycandidate instance, navigate to application details
          if (
            instance.relationships.context.find(
              (context) => context.role === Roles.VOORRANGSKANDIDAAT_HOUDER
            ).relationships.related_instance.identification === getters.userId
          ) {
            route.name = route.params.name = RoutesNames.SOLLICITATIE_DETAILS;
          } else {
            // Else, navigate to priority candidate route within the capacity request
            route.name = route.params.name = RoutesNames.KANDIDAAT;
          }
          break;

        case Identifications.TALENTPOOL_PROFIEL:
          route.name = route.params.name = RoutesNames.TALENTPOOL_PROFIEL;
          route.params.instanceId = instanceId;
          break;

        default:
          throw new StoreException(
            "Kan geen route bepalen voor gegeven identificatie."
          );
      }

      return route;
    },
  },

  mutations: {
    SET_TENANT: (state, payload) => Vue.set(state, "tenant", payload),

    SET_ORGANISATION: (state, payload) =>
      Vue.set(state, "organisation", payload),

    UPDATE_USER: (state, payload) =>
      Vue.set(
        state,
        "user",
        merge(state.user, payload, {
          arrayMerge: (_destinationArray, sourceArray) => sourceArray,
        })
      ),

    SET_USER_SERVICES: (state, payload) =>
      Vue.set(state, "userServices", payload),

    SET_CONTROLEER_AANVRAGEN: (state, payload) =>
      Vue.set(state, "controleerAanvragen", payload),

    CAN_ACCESS_MIJN_RECRUITMENT: (state, payload) =>
      Vue.set(state, "canAccessMijnRecruitment", payload),
    CAN_ACCESS_MIJN_WERVING_EN_SELECTIE: (state, payload) =>
      Vue.set(state, "canAccessMijnWervingEnSelectie", payload),
    CAN_ACCESS_MIJN_LOOPBAANADVIES: (state, payload) =>
      Vue.set(state, "canAccessMijnLoopbaanadvies", payload),
    CAN_ACCESS_DATABASE_PROFIELEN: (state, payload) =>
      Vue.set(state, "canAccessDatabaseProfielen", payload),
    CAN_ACCESS_MIJN_TALENTEN: (state, payload) =>
      Vue.set(state, "canAccessMijnTalenten", payload),
    CAN_ACCESS_MIJN_TAKEN: (state, payload) =>
      Vue.set(state, "canAccessMijnActies", payload),
    CAN_ACCESS_FAQ: (state, payload) => Vue.set(state, "canAccessFaq", payload),
    IS_ACCESS_CHECKED: (state, payload) =>
      Vue.set(state, "isAccessChecked", payload),
  },
  modules: {
    tenantStore: tenantStore,
    omniselect: omniselect,
    instanceDefinitions: instanceDefinitions,
    search: search,
    genericInstance: genericInstance,
    activity: activity,
    instanceFlow: instanceFlow,
    notifications: notifications,
    chat: chat,
    media: media,
    candidates: candidates,
    talentProfile: talentProfile,
    recruitment: recruitment,
    requests: requests,
    loopbaanadvies: loopbaanadvies,
    applications: applications,
    process: processStore,
    matchbox: matchbox,
    recruitmentText: recruitmentText,
    configurations: configurations,
    emailTemplates: emailTemplates,
    breadcrumbs: breadcrumbs,
    mapping: mapping,
    conceptDefinitions: conceptDefinitions,
    talentPoolProfile: talentPoolProfile,
  },
});

import Vue from "vue";
import IdUtils from "@/utils/IdUtils";
import { StoreException } from "@/utils/ErrorHandler";

const state = {
  selectedTalentPoolProfileIndex: 0,
  selectedTalentPoolProfile: {},
  allTalentPoolProfiles: {},
  showLogbook: false,
  vacanciesUpdate: false,
};

const getters = {
  selectedTalentPoolProfileIndex: (state) =>
    state.selectedTalentPoolProfileIndex,
  selectedTalentPoolProfile: (state) => state.selectedTalentPoolProfile,
  allTalentPoolProfiles: (state) => state.allTalentPoolProfiles,
  showLogbook: (state) => state.showLogbook,
  vacanciesUpdate: (state) => state.vacanciesUpdate,
};

const actions = {
  /**
   * Function to set the value of allTalentPoolProfiles and default selected index
   * @param commit
   * @param data
   */
  setAllTalentPoolProfiles: ({ commit }, data) => {
    commit("SET_ALL_TALENT_POOL_PROFILES", data);
    commit("SET_SELECTED_TALENT_POOL_PROFILE_INDEX", 0);
  },

  /**
   * Function to set the value of selectedTalentPoolProfileIndex and fetch relevant profile
   * @param commit
   * @param dispatch
   * @param index
   * @returns {Promise<void>}
   */
  setSelectedTalentPoolProfileIndex: async ({ commit, dispatch }, index) => {
    commit("SET_SELECTED_TALENT_POOL_PROFILE_INDEX", index);
    if (index || index === 0) {
      await dispatch("getTalentPoolProfile", { index: index });
    }
  },

  /**
   * Function to set the value of selectedTalentPoolProfile
   * @param commit
   * @param instance
   */
  setSelectedTalentPoolProfile: ({ commit }, instance) => {
    commit("SET_SELECTED_TALENT_POOL_PROFILE", instance);
  },

  /**
   * Function to fetch talent pool profile indicated with an index
   * @param getters
   * @param dispatch
   * @param commit
   * @param index
   * @param profileId
   * @returns {Promise<void>}
   */
  getTalentPoolProfile: async (
    { getters, dispatch, commit },
    { index, profileId }
  ) => {
    let instanceId = profileId;
    if (!IdUtils.isUUID(instanceId)) {
      if (!getters.allTalentPoolProfiles) {
        throw new StoreException("No data found");
      }

      const instance = getters.allTalentPoolProfiles[index];
      if (!instance) {
        throw new StoreException("No instance found");
      }

      if (!IdUtils.isUUID(instance?.identification)) {
        throw new StoreException("Invalid ID");
      }
      instanceId = instance.identification;
    }

    try {
      const talentPoolProfile = await dispatch(
        "genericInstance/getGenericInstance",
        instanceId,
        { root: true }
      );
      commit("SET_SELECTED_TALENT_POOL_PROFILE", talentPoolProfile);
    } catch (error) {
      throw new StoreException(error);
    }
  },

  setShowLogbook: ({ commit }, value) => {
    commit("SET_SHOW_LOGBOOK", value);
  },

  setVacanciesUpdate: ({ commit }, value) => {
    commit("SET_VACANCIES_UPDATE", value);
  },
};

const mutations = {
  SET_ALL_TALENT_POOL_PROFILES: (state, payload) =>
    Vue.set(state, "allTalentPoolProfiles", payload),
  SET_SELECTED_TALENT_POOL_PROFILE: (state, payload) =>
    Vue.set(state, "selectedTalentPoolProfile", payload),
  SET_SELECTED_TALENT_POOL_PROFILE_INDEX: (state, payload) =>
    Vue.set(state, "selectedTalentPoolProfileIndex", payload),
  SET_SHOW_LOGBOOK: (state, payload) => Vue.set(state, "showLogbook", payload),
  SET_VACANCIES_UPDATE: (state, payload) =>
    Vue.set(state, "vacanciesUpdate", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import Vue from "vue";
import { ConfigurationServices } from "@/services/ConfigurationServices";
import { StoreException } from "@/utils/ErrorHandler";
import IdUtils from "@/utils/IdUtils";
import { StandardFilters } from "@/utils/GeneralConstants";

const state = {
  configurationServices: null,
  configurations: [],
  buttonPermissionsConfig: null,
  selectedPreset: null,
  personalPresets: null,
};

/*
 * Create getters for all items in state
 */
const getters = {
  configurationServices: (state) => state.configurationServices,
  buttonPermissionsConfig: (state) => state.buttonPermissionsConfig,
  selectedPreset: (state) => state.selectedPreset,
  personalPresets: (state) => state.personalPresets,
};

const actions = {
  /**
   * Getting the configurationService. When configurationService is not set, set the configurationService.
   * @param commit
   * @param getters
   * @param rootGetters
   * @returns {Promise<getters.configurationServices|(function(*): (null|getters.configurationServices|(function(*))))>}
   */
  retrieveConfigurationServices: async ({ commit, getters, rootGetters }) => {
    try {
      if (getters.configurationServices == null) {
        if (rootGetters.tenantId === "") {
          throw new Error("Tenant id not found.");
        }

        commit(
          "SET_CONFIGURATION_SERVICES",
          new ConfigurationServices(rootGetters.organisationId)
        );
      }
      return getters.configurationServices;
    } catch (error) {
      throw new StoreException("retrieveConfigurationServices: " + error);
    }
  },

  /**
   * Retrieves configuration by reference
   * @param commit
   * @param dispatch
   * @param payload
   * @returns {Promise<*>}
   */
  retrieveConfigurationByReference: async ({ commit, dispatch }, payload) => {
    // Input validation
    if (
      !payload ||
      !payload.hasOwnProperty("code") ||
      !payload.hasOwnProperty("reference") ||
      !IdUtils.isUUID(payload.reference)
    ) {
      throw new StoreException("Invalid configuration reference");
    }
    try {
      const configurationServices = await dispatch(
        "retrieveConfigurationServices"
      );
      const config = await configurationServices.getConfigByReference(payload);

      if (payload.code === "BUTTON_PERMISSIONS") {
        commit("SET_BUTTON_PERMISSIONS_CONFIG", config);
      }

      return config;
    } catch (error) {
      throw new StoreException("retrieveConfiguration: " + error);
    }
  },

  /**
   * Retrieves all filters for a provided tenant, organisation and user
   * @param code
   * @param commit
   * @param dispatch
   * @param rootGetters
   * @returns {Promise<*[]>}
   */
  retrievePersonalPresets: async (
    { commit, dispatch, rootGetters },
    code = StandardFilters.WSM_FILTER
  ) => {
    let references = [
      rootGetters.tenantId,
      rootGetters.organisationId,
      rootGetters.userId,
    ];
    const roles = await dispatch("retrieveRolesOfUser");

    const rolesIDs = [];
    if (roles && Array.isArray(roles) && roles.length) {
      for (const role of roles) {
        if (
          role &&
          role.constructor === Object &&
          role.hasOwnProperty("identification")
        ) {
          rolesIDs.push(role.identification);
        }
      }
    }

    if (rolesIDs.length) {
      references = references.concat(rolesIDs);
    }

    const presets = [];
    let reference, preset;

    for (reference of references) {
      try {
        preset = await dispatch("retrieveConfigurationByReference", {
          code: code,
          reference: reference,
        });

        if (
          preset.hasOwnProperty("data") &&
          preset.data.hasOwnProperty("filters") &&
          Array.isArray(preset.data.filters)
        ) {
          preset.data.filters.forEach((item) => presets.push(item));
        } else if (
          preset &&
          preset.hasOwnProperty("data") &&
          typeof preset.data === "object"
        ) {
          presets.push(preset.data);
        }
      } catch (e) {
        // do nothing
      }
    }
    commit("SET_PERSONAL_PRESETS", presets);
    return presets;
  },

  /**
   * Retrives all the roles of a logged user
   * @param rootGetters
   * @returns {Promise<*[]>}
   */
  retrieveRolesOfUser: async ({ rootGetters }) => {
    const roles = [];
    const user = rootGetters.user;
    const currentDateTime = new Date();

    if (user.hasOwnProperty("relationships")) {
      if (user.relationships.hasOwnProperty("roles")) {
        user.relationships.roles.forEach((role) => {
          if (
            !role.hasOwnProperty("valid_until") &&
            role.hasOwnProperty("valid_from")
          ) {
            if (
              new Date(role.valid_from) < currentDateTime &&
              role.hasOwnProperty("role")
            ) {
              roles.push(role.role);
            }
          } else if (
            role.hasOwnProperty("valid_until") &&
            role.hasOwnProperty("valid_from")
          ) {
            if (
              new Date(role.valid_from) < currentDateTime &&
              currentDateTime < new Date(role.valid_until) &&
              role.hasOwnProperty("role")
            ) {
              roles.push(role.role);
            }
          }
        });
      }
    }
    return roles;
  },

  /**
   * Creates a new preset – selection of columns and a query
   * @param commit
   * @param dispatch
   * @param rootGetters
   * @param name
   * @param query
   * @param columns
   * @param code
   * @returns {Promise<void>}
   */
  createPreset: async (
    { commit, dispatch, rootGetters },
    { name, query, columns, code = StandardFilters.WSM_FILTER }
  ) => {
    let presets;
    let userPreset = null;

    try {
      userPreset = await dispatch("retrieveConfigurationByReference", {
        code: code,
        reference: rootGetters.userId,
      });

      presets = userPreset.data;
    } catch (e) {
      presets = null;
    }

    if (!presets) {
      const firstName = rootGetters.user.alias.split(" ")[0];
      presets = {
        label: firstName + "'s filters",
        value: "USER",
        children: [],
      };
    }

    const identification = IdUtils.generateUUID();
    presets.children.push({
      value: identification,
      label: name,
      query: query,
      columns: columns,
      canBeDeleted: true,
    });

    if (userPreset) {
      const config = userPreset;
      config.data = presets;

      await dispatch("updateConfiguration", config);
    } else {
      const config = {
        code: code,
        description: "USER FILTERS",
        reference: rootGetters.userId,
        data: presets,
      };
      await dispatch("createConfiguration", config);
    }

    await dispatch("retrievePersonalPresets", code);

    commit("SET_SELECTED_PRESET", ["USER", identification]);
    return identification;
  },

  /**
   * Deletes a preset based on its identification
   * @param commit
   * @param dispatch
   * @param rootGetters
   * @param identification
   * @param code
   * @returns {Promise<void>}
   */
  deletePreset: async (
    { commit, dispatch, rootGetters },
    { identification, code = StandardFilters.WSM_FILTER }
  ) => {
    const userFilter = await dispatch("retrieveConfigurationByReference", {
      code: code,
      reference: rootGetters.userId,
    });

    const filters = userFilter.data;
    filters.children = filters.children.filter(
      (filter) => filter.value !== identification
    );

    if (userFilter) {
      const config = userFilter;
      config.data = filters;

      await dispatch("updateConfiguration", config);
    }

    await dispatch("retrievePersonalPresets");

    commit("SET_SELECTED_PRESET", ["USER", identification]);
  },

  /**
   * Update a configuration
   * @param dispatch
   * @param payload
   * @returns {Promise<*>}
   */
  updateConfiguration: async ({ dispatch }, payload) => {
    if (
      !payload ||
      !payload.hasOwnProperty("code") ||
      !payload.hasOwnProperty("identification") ||
      !payload.hasOwnProperty("description") ||
      !payload.hasOwnProperty("reference") ||
      !payload.hasOwnProperty("data")
    ) {
      throw new StoreException(
        "Missing required parameter to create a configuration"
      );
    }
    try {
      const configurationServices = await dispatch(
        "retrieveConfigurationServices"
      );
      return await configurationServices.updateConfig(payload);
    } catch (error) {
      throw new StoreException(error);
    }
  },

  /**
   * Create a configuration
   * @param dispatch
   * @param payload
   * @returns {Promise<*>}
   */
  createConfiguration: async ({ dispatch }, payload) => {
    if (
      !payload.hasOwnProperty("code") ||
      !payload.hasOwnProperty("description") ||
      !payload.hasOwnProperty("reference") ||
      !payload.hasOwnProperty("data")
    ) {
      throw new StoreException(
        "Missing required parameter to create a configuration"
      );
    }

    try {
      const configurationServices = await dispatch(
        "retrieveConfigurationServices"
      );
      return await configurationServices.createConfig(payload);
    } catch (error) {
      throw new StoreException("createConfiguration: " + error);
    }
  },
};

const mutations = {
  SET_CONFIGURATION_SERVICES: (state, payload) =>
    Vue.set(state, "configurationServices", payload),
  SET_BUTTON_PERMISSIONS_CONFIG: (state, payload) =>
    Vue.set(state, "buttonPermissionsConfig", payload),
  SET_SELECTED_PRESET: (state, payload) =>
    Vue.set(state, "selectedPreset", payload),
  SET_PERSONAL_PRESETS: (state, payload) =>
    Vue.set(state, "personalPresets", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

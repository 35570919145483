import Vue from "vue";
import { StoreException } from "@/utils/ErrorHandler";
import { InstanceFlowServices } from "@/services/InstanceFlowServices";
import { AanvraagSpecialActions } from "@/utils/GeneralConst/CapacityRequest";
import { ActivityStates } from "@/utils/GeneralConstants";

const state = {
  instanceFlowServices: null,
};

const getters = {
  instanceFlowServices: (state) => state.instanceFlowServices,
};

const actions = {
  /**
   * Internal action for initializing and returning the InstanceFlowServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.tenantServices|(function(*): (null|getters.tenantServices|(function(*))))|null>}
   */
  getInstanceFlowServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.instanceFlowServices) {
        commit(
          "SET_INSTANCE_FLOW_SERVICES",
          new InstanceFlowServices(rootGetters.organisationId)
        );
      }

      return getters.instanceFlowServices;
    } catch (error) {
      throw new StoreException("retrieveTenantServices: " + error.message);
    }
  },

  /**
   * Updates the internal services with a new organisation id, if the internal services have already
   * been created. Otherwise the organisation id will be set on creation.
   *
   * @param dispatch
   * @param rootGetters
   * @return {Promise<void>}
   */
  updateInstanceFlowServices: ({ getters, rootGetters }) => {
    try {
      const instanceFlowServices = getters.instanceFlowServices;
      if (instanceFlowServices) {
        instanceFlowServices.setOrganisation(rootGetters.organisationId);
      }
    } catch (error) {
      console.error(error);
    }
  },

  getInstanceMonitoring: async ({ dispatch }, instanceId) => {
    try {
      const instanceFlowServices = await dispatch("getInstanceFlowServices");

      return await instanceFlowServices.getInstanceMonitoring(instanceId);
    } catch (error) {
      console.error(error);
    }
  },

  getInstanceFlow: async ({ dispatch }, instanceId) => {
    try {
      const instanceFlowServices = await dispatch("getInstanceFlowServices");

      return await instanceFlowServices.getInstanceFlow(instanceId);
    } catch (error) {
      console.error(error);
    }
  },

  startInstanceFlow: async ({ dispatch }, payload) => {
    try {
      const instanceFlowServices = await dispatch("getInstanceFlowServices");

      return await instanceFlowServices.startInstanceFlow(
        payload.instanceId,
        payload.flowId
      );
    } catch (error) {
      console.error(error);
    }
  },

  finishInstanceFlow: async ({ dispatch }, payload) => {
    try {
      const instanceFlowServices = await dispatch("getInstanceFlowServices");

      return await instanceFlowServices.finishInstanceFlow(
        payload.instanceId,
        payload.flowId
      );
    } catch (error) {
      console.error(error);
    }
  },

  getStage: async ({ dispatch }, payload) => {
    try {
      const instanceFlowServices = await dispatch("getInstanceFlowServices");
      return await instanceFlowServices.getStage(
        payload.instanceId,
        payload.stageId
      );
    } catch (error) {
      console.error(error);
    }
  },

  getActivityDefinitionData: async ({ dispatch }, activityIdentification) => {
    try {
      const instanceFlowServices = await dispatch("getInstanceFlowServices");

      return await instanceFlowServices.getActivityDefinitionData(
        activityIdentification
      );
    } catch (error) {
      console.error(error);
    }
  },

  setInputData: async ({ dispatch }, payload) => {
    try {
      const instanceFlowServices = await dispatch("getInstanceFlowServices");

      return await instanceFlowServices.setInputData(
        payload.activityId,
        payload.input
      );
    } catch (error) {
      console.error(error);
    }
  },

  mapInput: async ({ dispatch }, activityId) => {
    try {
      const instanceFlowServices = await dispatch("getInstanceFlowServices");

      return await instanceFlowServices.mapInput(activityId);
    } catch (error) {
      console.error(error);
    }
  },

  getActivity: async ({ dispatch }, activityId) => {
    try {
      const instanceFlowServices = await dispatch("getInstanceFlowServices");

      return await instanceFlowServices.getActivity(activityId);
    } catch (error) {
      console.error(error);
    }
  },

  startActivity: async ({ dispatch }, activityId) => {
    try {
      const instanceFlowServices = await dispatch("getInstanceFlowServices");

      return await instanceFlowServices.startActivity(activityId);
    } catch (error) {
      console.error(error);
    }
  },

  finishActivity: async ({ dispatch }, activityId) => {
    try {
      const instanceFlowServices = await dispatch("getInstanceFlowServices");

      return await instanceFlowServices.finishActivity(activityId);
    } catch (error) {
      console.error(error);
    }
  },

  validateActivity: async ({ dispatch }, activityId) => {
    try {
      const instanceFlowServices = await dispatch("getInstanceFlowServices");

      return await instanceFlowServices.validateActivity(activityId);
    } catch (error) {
      console.error(error);
    }
  },

  getActions: async ({ dispatch }, instanceId) => {
    try {
      const instanceFlowServices = await dispatch("getInstanceFlowServices");
      const allActions = await instanceFlowServices.getActions(instanceId);
      const specialActions = [];
      const otherActions = [];
      const specialIdentifications = Object.values(AanvraagSpecialActions);

      if (allActions && allActions.stages_list) {
        const actions = allActions.stages_list.filter(
          (stage) =>
            stage.definition.type !== "composite" &&
            (stage.state === ActivityStates.CREATED ||
              stage.state === ActivityStates.STARTED)
        );

        for (const action of actions) {
          if (
            action.definition.identification &&
            specialIdentifications.includes(action.definition.identification)
          ) {
            specialActions.push(action);
          } else {
            otherActions.push(action);
          }
        }
      }

      return [allActions, specialActions, otherActions];
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  SET_INSTANCE_FLOW_SERVICES: (state, payload) =>
    Vue.set(state, "instanceFlowServices", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

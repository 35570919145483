import Vue from "vue";
import { GenericInstanceServices } from "@/services/GenericInstanceServices";
import { StoreException } from "@/utils/ErrorHandler";
import IdUtils from "@/utils/IdUtils";
import { BulkActions } from "@/utils/GeneralConstants";
import { ProcessServices } from "@/services/ProcessServices";

const state = {
  genericInstanceServices: null,
  processServices: null,
};

const getters = {
  genericInstanceServices: (state) => state.genericInstanceServices,
  processServices: (state) => state.processServices,
};

const actions = {
  /**
   * Internal action for initializing and returning the GenericInstanceServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.tenantServices|(function(*): (null|getters.tenantServices|(function(*))))|null>}
   */
  getGenericInstanceServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.genericInstanceServices) {
        commit(
          "SET_GENERIC_INSTANCE_SERVICES",
          new GenericInstanceServices(rootGetters.organisationId)
        );
      }
      return getters.genericInstanceServices;
    } catch (error) {
      throw new StoreException("getGenericInstanceServices: " + error.message);
    }
  },

  /**
   * Internal action for initializing and returning the ProcessServices API object.
   * @param getters
   * @param rootGetters
   * @param commit
   * @return {Promise<getters.processServices|(function(*): (null|getters.processServices|(function(*))))|null>}
   */
  getProcessServices: async ({ getters, rootGetters, commit }) => {
    try {
      if (null === getters.processServices) {
        commit(
          "SET_PROCESS_SERVICES",
          new ProcessServices(rootGetters.organisationId)
        );
      }

      return getters.processServices;
    } catch (error) {
      throw new StoreException("getProcessServices: " + error.message);
    }
  },

  /**
   * Fetches single activity's schema
   * @param dispatch
   * @param instanceId
   * @param activityDefinitionId
   * @returns {Promise<*>}
   */
  getActivitySchema: async (
    { dispatch },
    { instanceId, activityDefinitionId }
  ) => {
    if (!instanceId) {
      throw new StoreException("No instanceId provided");
    }

    const genericInstanceServices = await dispatch(
      "getGenericInstanceServices"
    );
    return await genericInstanceServices.getActivitySchema(
      instanceId,
      activityDefinitionId
    );
  },

  /**
   * Fetches bulk actions available for selected instances
   * @param dispatch
   * @param instanceIds
   * @returns {Promise<*|undefined>}
   */
  getAvailableBulkActions: async ({ dispatch }, instanceIds) => {
    // Check if supplied data is an array of UUIDs
    if (!Array.isArray(instanceIds)) {
      throw new StoreException("Supplied data needs to be of the type array.");
    }
    if (!instanceIds || !instanceIds.length) {
      throw new StoreException("The array can't be empty");
    }
    if (instanceIds.map((id) => IdUtils.isUUID(id)).some((el) => !el)) {
      throw new StoreException(
        "Supplied data needs to only includes instances' IDs."
      );
    }

    try {
      const genericInstanceServices = await dispatch(
        "getGenericInstanceServices"
      );
      const bulkActions = await genericInstanceServices.getBulkActions(
        instanceIds
      );
      return bulkActions && bulkActions.data && bulkActions.data.stages
        ? bulkActions.data.stages.filter((stage) =>
            Object.values(BulkActions).includes(stage.definition.identification)
          )
        : [];
    } catch (error) {
      throw new StoreException(
        "Something went wrong while trying to get the available actions: " +
          error.message
      );
    }
  },

  /**
   * Starts the process of performing a bulk action
   * @param dispatch
   * @param payload
   * @returns {Promise<*>}
   */
  prepareAvailableBulkAction: async (
    { dispatch },
    { stageId, activityType, instanceIds, body }
  ) => {
    if (!stageId || !activityType || !instanceIds) {
      throw new StoreException(
        "stageId, activityType and instanceIds need to be supplied"
      );
    }
    if (!activityType || typeof activityType !== "string") {
      throw new StoreException("No valid activityType provided");
    }
    if (!instanceIds || !Array.isArray(instanceIds)) {
      throw new StoreException("No valid instanceIds provided");
    }

    try {
      const genericInstanceServices = await dispatch(
        "getGenericInstanceServices"
      );

      return await genericInstanceServices.prepareBulkAction(
        stageId,
        instanceIds,
        body
      );
    } catch (error) {
      throw new StoreException(
        "Something went wrong while trying to prepare the action: " +
          error.message
      );
    }
  },

  /**
   * Finishes the process of performing a bulk action
   * @param dispatch
   * @param stageId
   * @param activityType
   * @param instanceIds
   * @param body
   * @param payload
   * @returns {Promise<*|undefined>}
   */
  executeAvailableBulkAction: async (
    { dispatch },
    { stageId, activityType, instanceIds, body }
  ) => {
    if (!stageId || !activityType || !instanceIds) {
      throw new StoreException(
        "stageId, activityType and instanceIds need to be supplied"
      );
    }
    if (!activityType || typeof activityType !== "string") {
      throw new StoreException("No valid activityType provided");
    }
    if (!instanceIds || !Array.isArray(instanceIds)) {
      throw new StoreException("No valid instanceIds provided");
    }

    try {
      const genericInstanceServices = await dispatch(
        "getGenericInstanceServices"
      );

      return await genericInstanceServices.executeBulkAction(
        stageId,
        activityType,
        instanceIds,
        body
      );
    } catch (error) {
      throw new StoreException(
        "Something went wrong while trying to execute the action: " +
          error.message
      );
    }
  },

  /**
   * Function to trigger (start) and execute process step of given instance with provided input
   * @param dispatch
   * @param stageId
   * @param instanceId
   * @param body
   * @returns {Promise<axios.AxiosResponse<T>>}
   */
  startAndExecuteAction: async (
    { dispatch },
    { stageId, instanceId, body }
  ) => {
    if (!stageId || !instanceId) {
      throw new StoreException("stageId and instanceId need to be supplied");
    }

    try {
      const processServices = await dispatch("getProcessServices");
      return await processServices.startAndExecuteStage(
        instanceId,
        stageId,
        body
      );
    } catch (error) {
      throw new StoreException(
        "Something went wrong while trying to execute the action: " +
          error.message
      );
    }
  },
};

const mutations = {
  SET_GENERIC_INSTANCE_SERVICES: (state, payload) =>
    Vue.set(state, "genericInstanceServices", payload),
  SET_PROCESS_SERVICES: (state, payload) =>
    Vue.set(state, "processServices", payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
